$header-vertical-padding: 10px !default;
$header-horizontal-padding: 25px !default;
$header-logo-height: 60px !default;
$header-menu-font-size: $base-font-size * 1.5 !default;
$header-font-family: $sans-font-family !default;
$header-submenu-indent: 24px !default;
$header-menu-line-color: mix($header-background, $header-text, 70%) !default;


$header-height: $header-logo-height + 2 * $header-vertical-padding;

div.header {
        background: $header-background;
        color: $header-text;
        font-family: $header-font-family;

        padding: $header-vertical-padding $header-horizontal-padding;
        @supports(padding-left: unquote("max(0px, 1px)")) {
            padding-left: unquote("max(#{$header-horizontal-padding}, env(safe-area-inset-left))");
            padding-right: unquote("max(#{$header-horizontal-padding}, env(safe-area-inset-right))");
        }
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 2;
        
        a {
                color: $header-text;
        }
        
        div.logo, div.menu {
                display: inline-block;
                vertical-align: middle;
        }
        div.logo {
                padding-right: $header-horizontal-padding;
        }
        img.logo {
                height: $header-logo-height;
        }

        div.menu {
            div.main {
                font-size: $header-menu-font-size;
                padding-bottom: 10px;
            }
            @media (max-width: $mid-breakpoint) {
                .sub .item.optional:not(.active) {
                    display: none;
                }
            }
        }

        @media (min-width: $large-breakpoint) {
            .item:not(:first-child):before {
                content: " · ";
            }
        }
        
        .item.active {
                font-weight: bold;
        }

        @media (max-width: $large-breakpoint - 1) {
            .item.inactive {
                display: none;
            }
        }
}

div.header-placeholder {
        height: $header-height;
}
