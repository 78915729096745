@media (min-width: $large-breakpoint) {
    div.hamburger {
        display: none;
    }
    div.hamburger-menu {
        display: none;
    }
}

input.btn {
    display: none;
}

@media (max-width: $large-breakpoint - 1) {
    div.hamburger {
        position: fixed;
        top: 0;
        right: 0;

        div.navicon {
            text-align: center;
            margin-right: 1.5ex;
            margin-top: $header-vertical-padding;
            font-size: $header-menu-font-size;
        }

        span.navicon {
            position: absolute;
            top: 0;
            right: 0;
            width: $header-menu-font-size;
            height: $header-menu-font-size;
            margin-right: 1.5ex;
            margin-top: $header-vertical-padding;
            transition: opacity .5s ease-out;
        }
        span.navicon.open {
            opacity: 1;
        }
        span.navicon.close {
            opacity: 0;
            top: 4px;
        }
        span.navicon.open:before {
            content: "☰";
        }
        span.navicon.close:before {
            content: "✕";
        }
    }

    .btn:checked ~ div.header span.navicon.open {
        opacity: 0;
    }
   .btn:checked ~ div.header span.navicon.close {
        opacity: 1;
    }

    div.hamburger-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
        background: $header-background;
        color: $header-text;
        font-family: $header-font-family;
        overflow: hidden;
        height: 0;
        transition: height .2s ease-out;

        a {
            color: $header-text;
        }

        nav.menu {
            margin: $header-height $header-horizontal-padding 1em;
        }

        ul {
            padding: 0;
            list-style: none;
        }

        li {
            list-style: none;
            padding: 1em 0;
            border-bottom: 1px solid $header-menu-line-color;
        }
        li:first-child {
            border-top: 1px solid $header-menu-line-color;
        }

        li.active {
            font-weight: bold;
        }

        li.sub {
            padding-left: $header-submenu-indent;
        }
    }

    .btn:checked ~ div.hamburger-menu {
        height: 100vh;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
