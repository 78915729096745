$big-logo-margin-top: 50px !default;
$big-logo-margin-bottom: $big-logo-margin-top !default;
$big-logo-height: 500px !default;

div.big-logo {
        text-align: center;
        margin-top: $big-logo-margin-top;
        margin-bottom: $big-logo-margin-bottom;
        
        img {
                height: $big-logo-height;
                max-width: 95%;
                max-height: 50vh;
        }
}
