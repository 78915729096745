$root-font-family: Merriweather;
$sans-font-family: MerriweatherSans;
$mono-font-family: PTMono;

$background: rgb(238, 238, 238);
$text-color: rgb(0, 0, 0);
$highlight-color: rgb(96, 125, 139);

$header-background: $highlight-color;
$header-text: rgb(255, 255, 255);

@import "merriweather";
@import "ptmono";
@import "nih";
