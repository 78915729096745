h1.title {
        margin-top: 3ex;
        margin-bottom: 0ex;
        font-family: $sans-font-family;
}

h2.date {
        color: $mid-color;
        font-size: $base-font-size;
        margin-top: 1ex;
        margin-bottom: 3ex;
}
