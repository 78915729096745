$footer-margin-top: 3ex !default;
$footer-margin-bottom: 3ex !default;

div.footer {
        text-align: center;
        
        margin-top: $footer-margin-top;
        margin-bottom: $footer-margin-bottom;
        
        .item:not(:first-child):before {
                content: " · ";
        }
}