div.mandoc {
        h3 {
                font-family: $sans-font-family;
                font-size: $base-font-size * 1.5;
        }
        h4 {
                font-family: $sans-font-family;
                font-size: $base-font-size * 1.25;
        }
        
        table.synopsis tr {
                vertical-align: top;
        }
}