$background: rgb(238, 238, 238) !default;
$text-color: black !default;
$highlight-color: rgb(96, 125, 139) !default;
$mid-color: mix(black, $highlight-color, 30%) !default;
$hr-width: 33% !default;
$hr-margin: 3ex !default;
$base-font-size: 16px !default;
$root-font-family: serif !default;
$sans-font-family: sans-serif !default;
$mono-font-family: monospace !default;

$large-breakpoint: 735px !default;
$mid-breakpoint: 800px !default;

html {
    background: $background;
    color: $text-color;
    font-family: $root-font-family;
    font-size: $base-font-size;
}

body {
    margin: 0;
    -webkit-text-size-adjust: none;
}

a {
    color: $highlight-color;
    text-decoration: none;
}

hr {
    width: 33%;
    margin-top: $hr-margin;
    margin-bottom: $hr-margin;
}

code, tt, pre {
    font-family: $mono-font-family;
}
