$page-width: 90ex !default;
$page-margin: 5ex !default;
$small-page-margin: 1em !default;

main#container {
        width: 100%;
}

div#container {
    max-width: $page-width;
    margin: 0 auto;
    text-align: center;

    @media (min-width: $large-breakpoint) {
        padding-left: $page-margin;
        padding-right: $page-margin;
        @supports(padding-left: unquote("max(0px, 1px)")) {
            padding-left: unquote("max(#{$page-margin}, env(safe-area-inset-left))");
            padding-right: unquote("max(#{$page-margin}, env(safe-area-inset-right))");
        }
    }
    @media (max-width: $large-breakpoint - 1 ) {
        padding-left: $small-page-margin;
        padding-right: $small-page-margin;
        @supports(padding-left: unquote("max(0px, 1px)")) {
            padding-left: unquote("max(#{$small-page-margin}, env(safe-area-inset-left))");
            padding-right: unquote("max(#{$small-page-margin}, env(safe-area-inset-right))");
        }
    }
    padding-bottom: env(safe-area-inset-bottom);
}

div#page {
        text-align: left;
}
