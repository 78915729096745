$project-state-margin-top: 30px !default;
$project-state-margin-bottom: 10px !default;
$project-state-title-font-family: $sans-font-family !default;
$project-state-title-color: $mid-color !default;
$project-state-title-weight: bold !default;
$project-state-title-size: 200% !default;
$project-state-title-skip: .5ex !default;
$project-state-tagline-color: $project-state-title-color !default;
$project-state-tagline-weight: bold !default;
$project-state-tagline-size: 100% !default;

$project-box-width: 350px !default;
$project-box-height: 200px !default;
$project-box-margin: 20px !default;
$project-box-padding: 20px !default;

$project-box-background: rgba(255, 255, 255, .9) !default;
$project-box-color: $text-color !default;
$project-box-shadow-color: rgba(12, 46, 82, .5) !default;
$project-box-border-color: transparentize($highlight-color, .85) !default;
$project-box-border-width: 1px !default;

$project-box-title-font-family: $sans-font-family !default;
$project-box-title-weight: bold !default;
$project-box-title-size: 200% !default;

$project-box-tagline-weight: normal !default;
$project-box-tagline-size: 100% !default;

body.home {
        width: 100%;
}
div.project-index {
        text-align: center;
}

div.project-state {
        margin-top: $project-state-margin-top;
        margin-bottom: $project-state-margin-bottom;
        max-width: 100%;
        margin-left: 1ex;
        margin-right: 1ex;
        
        .title {
                color: $project-state-title-color;
                font-family: $project-state-title-font-family;
                font-weight: $project-state-title-weight;
                font-size: $project-state-title-size;
                margin-bottom: $project-state-title-skip;
        }
        
        .tagline {
                color: $project-state-tagline-color;
                font-weight: $project-state-tagline-weight;
                font-size: $project-state-tagline-size;
        }
}

div.project-box {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        
        color: $project-box-color;
        background-color: $project-box-background;

        @media(max-width: $project-box-width + 81) {
                width: 75%;
        }
        @media(min-width: $project-box-width + 80) {
                width: $project-box-width;
        }
        height: $project-box-height;
        
        box-shadow: 0 10px 6px -6px $project-box-shadow-color;
        border-color: $project-box-border-color;
        border-width: $project-box-border-width;
        border-style: solid;
        
        padding: $project-box-padding;
        margin: $project-box-margin;
        
        .title {
                font-family: $project-box-title-font-family;
                font-weight: $project-box-title-weight;
                font-size: $project-box-title-size;
        }
        
        .tagline {
                font-weight: $project-box-tagline-weight;
                font-size: $project-box-tagline-size;
        }
}