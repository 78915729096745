$neopop-screenshot-title-skip: 2ex !default;
$neopop-screenshot-margin-bottom: 1ex !default;

div.NeoPop-screenshots {
        text-align: center;
        margin-bottom: $neopop-screenshot-margin-bottom;
        
        .title {
                padding-bottom: $neopop-screenshot-title-skip;
        }
        div.screenshot {
                display: inline-block;
        }
}
